import React from 'react'

import { Helmet } from 'react-helmet'

import HeaderArcobo from '../components/header-arcobo'
import FooterArcobo from '../components/footer-arcobo'
import './privacypolicy.css'

const Privacypolicy = (props) => {
  return (
    <div className="privacypolicy-container">
      <Helmet>
        <title>Privacypolicy - Arcobo</title>
        <meta
          name="description"
          content="Производство и металлообработка для крупногабаритных металлических изделий. Получите уникальные детали, изготовленные с профессиональным мастерством."
        />
        <meta property="og:title" content="Privacypolicy - Arcobo" />
        <meta
          property="og:description"
          content="Производство и металлообработка для крупногабаритных металлических изделий. Получите уникальные детали, изготовленные с профессиональным мастерством."
        />
      </Helmet>
      <HeaderArcobo rootClassName="header-arcobo-root-class-name2"></HeaderArcobo>
      <div className="privacypolicy-banner">
        <h1 className="privacypolicy-text">Privacy policy</h1>
        <span className="privacypolicy-text01">
          <span>Definitions</span>
          <br></br>
          <span>
            The internet project arcobo.tech takes the confidentiality of
            information of its clients and website visitors seriously.
          </span>
          <br></br>
          <span>
            Personal data - information regulated by the Offer Agreement,
            related to a specific individual or legal entity, recorded on an
            electronic, paper, or other material medium.
          </span>
          <br></br>
          <span>
            Collection of Personal Data - actions aimed at obtaining Personal
            Data.
          </span>
          <br></br>
          <span>
            Processing of Personal Data - actions aimed at accumulating,
            storing, modifying, supplementing, using, distributing,
            depersonalizing, blocking, and destroying Personal Data.
          </span>
          <br></br>
          <span>Website Usage Rules:</span>
          <br></br>
          <span>
            {' '}
            The data entered by the Buyer during registration on the
            Agent&apos;s Website must be accurate, and the Buyer is solely
            responsible for their accuracy;
          </span>
          <br></br>
          <span>
            {' '}
            The Buyer is responsible for the security and confidentiality of
            their password, all data provided during registration, and when
            entering the password;
          </span>
          <br></br>
          <span>
            {' '}
            The use of the Website should be carried out by the Buyer only for
            lawful purposes and by lawful means in accordance with the
            legislation of the Republic of Kazakhstan.
          </span>
          <br></br>
          <br></br>
          <span>Terms</span>
          <br></br>
          <span>
            2.1. In accordance with the current legislation of the Republic of
            Kazakhstan, regulating relations related to the Collection,
            Processing of Personal Data when the Buyer accepts, as defined by
            the Offer Agreement, the Buyer unconditionally consents to the
            Collection, Processing, cross-border transfer (if necessary) of the
            Buyer&apos;s Personal Data, not contradicting the law, for purposes
            regulated by the Offer Agreement, and from sources, including public
            ones, at the discretion of the Agent, in connection with existing or
            future civil law and/or other legal relationships with the Agent.
          </span>
          <br></br>
          <span>
            2.2. The Parties have defined the following information related to
            the Buyer, as well as any future changes and/or additions thereto,
            recorded on an electronic, paper, or other material medium, as the
            Buyer&apos;s Personal Data: last name, first name, patronymic (if
            any), Individual Identification Number (IIN), date of birth,
            citizenship, gender, place of work and position, postal address
            (residential address, registration address), home, work, mobile
            phone numbers, email address(es), and other personal data.
          </span>
          <br></br>
          <span>
            2.3. The Collection, Processing, and cross-border transfer (if
            necessary) of the Buyer&apos;s Personal Data is carried out by the
            Agent for the following purposes, including, but not limited to:
          </span>
          <br></br>
          <span>
            2.3.1. For the proper performance of the Offer Agreement, as well as
            the rights and obligations imposed on the Agent;
          </span>
          <br></br>
          <span>2.3.2. For internal control and accounting by the Agent;</span>
          <br></br>
          <span>
            2.3.3. For judicial and extrajudicial protection of the Agent&apos;s
            rights: in case of non-fulfillment of obligations under the relevant
            transactions; in case of disputes, including disputes with third
            parties;
          </span>
          <br></br>
          <span>
            2.3.4. For conducting research aimed at improving the quality of
            Services;
          </span>
          <br></br>
          <span>
            2.3.5. For conducting marketing programs, statistical research, and
            promoting Services by contacting the Buyer directly through various
            means of communication, including, but not limited to: postal
            mailings, emails, telephone and/or fax communication, the Internet,
            etc.;
          </span>
          <br></br>
          <span>
            2.3.6. In case third parties are involved in conducting marketing or
            other research (including analytical), Buyer&apos;s data may be
            transferred to third parties.
          </span>
          <br></br>
          <span>
            2.4. The Buyer, by providing Personal Data to the Agent via open
            communication channels, is aware of and accepts the risk of
            unauthorized access to them by third parties and the consequences
            thereof. The Agent is not responsible for the unauthorized access to
            Personal Data by third parties when transmitted via open
            communication channels.
          </span>
          <br></br>
          <span>
            2.5. The Buyer&apos;s consent to the Collection, Processing, and
            cross-border transfer (if necessary) of Personal Data, regulated by
            this section, may be revoked in cases provided for by the
            legislation of the Republic of Kazakhstan, based on a respective
            written statement, which is submitted to the Agent no later than 30
            (thirty) calendar days before the date of revocation of this
            consent. The Buyer is aware that this consent cannot be revoked in
            cases where such revocation would contradict the legislation or in
            the presence of unfulfilled obligations of the Buyer to the Agent.
          </span>
          <br></br>
          <span>
            2.6. The Buyer agrees that for the conclusion and performance of the
            Agreement, the personal data of the Buyer may be transferred by the
            Agent to third parties under the conditions regulated in this
            article of the Agreement.
          </span>
          <br></br>
          <span>
            2.7. The Buyer is solely responsible for the content and accuracy of
            the data provided when concluding the Agreement.
          </span>
          <br></br>
          <span>
            2.8. When paying for the Order using bank cards, information about
            the card and its holder is transmitted in a secure form to the
            bank&apos;s authorized server and is not provided to the Agent or
            third parties. In this case, no confidential information, except for
            notification of payment to the Agent, is transferred.
          </span>
          <br></br>
          <span>
            2.9. The Agent has the right to record telephone conversations with
            the Buyer. Telephone conversations are recorded for the purpose of
            monitoring the activities of the Agent&apos;s operators and
            monitoring the quality of Order execution.
          </span>
          <br></br>
          <span>
            2.10. The Buyer agrees that in case of failure to fulfill
            obligations to fulfill the Order of the Goods to the specified
            address of the Buyer due to the provision by the Buyer of
            inaccurate, incomplete, and/or invalid data (absence of the Buyer at
            the specified address), the Buyer is responsible for any damage
            and/or expenses incurred by the Agent.
          </span>
          <br></br>
          <span>
            2.11. The Buyer agrees that the Agent is not responsible for the
            accuracy of all informational materials and any information that is
            of a reference nature and cannot fully convey accurate information
            about the Goods (including the cost and availability of the Goods
            from Suppliers), as well as contain errors.
          </span>
          <br></br>
          <span>
            It is prohibited to use the Services for the distribution of
            advertising information without the consent of the Agent.
          </span>
          <br></br>
          <br></br>
          <span>Contacts</span>
          <br></br>
          <span>
            For questions regarding this policy, please contact us at
            info@arcobo.tech or by phone +77001234567.
          </span>
        </span>
      </div>
      <FooterArcobo rootClassName="footer-arcobo-root-class-name3"></FooterArcobo>
    </div>
  )
}

export default Privacypolicy
