import React from 'react'

import { Helmet } from 'react-helmet'

import HeaderArcobo from '../components/header-arcobo'
import FeatureCard3 from '../components/feature-card3'
import FeatureCard1 from '../components/feature-card1'
import GalleryCard1 from '../components/gallery-card1'
import FooterArcobo from '../components/footer-arcobo'
import './home.css'

const Home = (props) => {
  return (
    <div className="home-container">
      <Helmet>
        <title>Arcobo</title>
        <meta
          name="description"
          content="Производство и металлообработка для крупногабаритных металлических изделий. Получите уникальные детали, изготовленные с профессиональным мастерством."
        />
        <meta property="og:title" content="Arcobo" />
        <meta
          property="og:description"
          content="Производство и металлообработка для крупногабаритных металлических изделий. Получите уникальные детали, изготовленные с профессиональным мастерством."
        />
      </Helmet>
      <HeaderArcobo rootClassName="header-arcobo-root-class-name"></HeaderArcobo>
      <div className="home-hero">
        <h1 className="home-heading">
          Manufacturing large-scale metal products in record time using 3D
          printing
        </h1>
        <div className="home-btn-group">
          <a
            href="https://calc.arcobo.tech"
            target="_blank"
            rel="noreferrer noopener"
            className="home-link button"
          >
            Calculate the cost ONLINE
          </a>
          <div className="home-btn-group1">
            <span className="home-text">
              Experience the efficiency and precision of our metal fabrication
              services for large-scale projects. Trust us to bring your vision
              to life with cutting-edge technology and unparalleled expertise
            </span>
          </div>
        </div>
      </div>
      <div className="home-hero1">
        <div className="home-container1">
          <h1 className="home-text01">What do we offer?</h1>
          <h1 className="home-text02">
            <span className="home-text03">
              We specialize in crafting large-scale metal products, offering
              expertise in materials such as steel, stainless steel, aluminum
              alloys, titanium alloys, bronze, and other weldable materials. Our
              state-of-the-art manufacturing facility is situated in Almaty,
              Kazakhstan, ensuring meticulous attention to detail and quality
              control. Our advantage lies in our ability to deliver
              manufacturing within just a few days, ensuring swift production
              without compromising quality.
            </span>
            <br></br>
          </h1>
        </div>
        <img
          alt="image"
          src="/images/welding_robot-1200w.png"
          className="home-image"
        />
      </div>
      <div className="home-features">
        <h1 className="home-text05">Our manufacturing process</h1>
        <span className="home-text06">
          Our team is ready to take on the full production cycle of your metal
          product - from optimizing the 3D model to final machining. In our
          work, we use the most modern and high-tech equipment. A personalized
          approach to each order helps reduce your production costs and speed up
          the production time of the part.
        </span>
        <div className="home-container2">
          <FeatureCard3
            title="3D model"
            imageSrc="/icons/3dmodel.svg"
            description="To calculate online, you need to upload the model in STL format or send the file in any other format to us by email"
            rootClassName="rootClassName"
          ></FeatureCard3>
          <FeatureCard3
            title="Printing"
            imageSrc="/icons/3dprinting.svg"
            description="We will manufacture a blank specifically for your product using our own high-tech equipment"
            rootClassName="rootClassName3"
          ></FeatureCard3>
          <FeatureCard3
            title="Machining"
            imageSrc="/icons/machining.svg"
            description="We will perform all necessary machining using our 4 and 5-axis CNC machines"
            rootClassName="rootClassName5"
          ></FeatureCard3>
          <FeatureCard3
            title="End-product"
            imageSrc="/icons/end_product.svg"
            description="You will receive the required product of the highest quality in the shortest possible time"
            rootClassName="rootClassName1"
          ></FeatureCard3>
        </div>
      </div>
      <div className="home-features1">
        <h1 className="home-text07">
          <span>Addressing the Problem</span>
          <br></br>
        </h1>
        <span className="home-text10">
          In addressing the challenge of manufacturing large-scale custom parts
          using traditional methods, we recognize the significant drawbacks of
          cost and time. Conventional manufacturing processes often prove
          prohibitively expensive and time-consuming, making it impractical for
          many projects. As a solution, we specialize in leveraging the
          capabilities of 3D printing to provide a more efficient and
          cost-effective alternative.
        </span>
        <div className="home-features2">
          <h1 className="home-text11">
            <span>Discover our </span>
            <span>unique features</span>
          </h1>
          <div className="home-separator"></div>
          <div className="home-container3">
            <div className="home-container4">
              <FeatureCard1
                title="Maximum dimensions are not limited"
                imageSrc="/icons/max_dimensions.svg"
                description="you can print products from a few centimeters to tens of meters"
                rootClassName="rootClassName1"
              ></FeatureCard1>
              <FeatureCard1
                title="MAX performance"
                imageSrc="/icons/performance.svg"
                description="printing up to 5 kg/h - a record among additive technologies"
                rootClassName="rootClassName4"
              ></FeatureCard1>
              <FeatureCard1
                title="Versatility and quality"
                imageSrc="/icons/iso_standart.svg"
                description="more than a hundred certified alloys, product properties are not inferior to traditional methods"
                rootClassName="rootClassName3"
              ></FeatureCard1>
              <FeatureCard1
                title="Cheaper than other additive technologies"
                imageSrc="/icons/discount.svg"
                description="due to the use of wire, which is several times cheaper than powder"
                rootClassName="rootClassName2"
              ></FeatureCard1>
            </div>
            <div className="home-container5">
              <span className="home-text14">relativityspace.com</span>
            </div>
          </div>
        </div>
      </div>
      <h1 className="home-text15">
        <span>The Era of Additive Technologies Has Arrived</span>
        <br></br>
      </h1>
      <div className="home-gallery">
        <GalleryCard1
          title="Complex and anthropomorphic designs"
          imageSrc="/images/mx3d-1500w.png"
          subtitle="mx3d.com"
          rootClassName="rootClassName"
        ></GalleryCard1>
        <GalleryCard1
          title="Large-scale custom-shaped products"
          imageSrc="/images/ramlab-1500w.png"
          subtitle="ramlab.com"
          rootClassName="rootClassName1"
        ></GalleryCard1>
        <GalleryCard1
          title="Waffle-shaped details, typically milled from large billets"
          imageSrc="/images/waammat-1500w.png"
          subtitle="waammat.com"
          rootClassName="rootClassName2"
        ></GalleryCard1>
      </div>
      <div className="home-banner">
        <div className="home-container6">
          <h1 className="home-text18">We&apos;re right here!</h1>
          <span className="home-text19">
            <span>
              Our manufacturing location in
              <span
                dangerouslySetInnerHTML={{
                  __html: ' ',
                }}
              />
            </span>
            <span>Almaty</span>
            <span>
              , Kazakhstan, positions us as a unique player in the machinery
              market across Central Asia.
            </span>
          </span>
        </div>
        <img alt="image" src="/images/map-1400w.png" className="home-image1" />
      </div>
      <FooterArcobo rootClassName="footer-arcobo-root-class-name"></FooterArcobo>
    </div>
  )
}

export default Home
