import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './footer-arcobo.css'

const FooterArcobo = (props) => {
  return (
    <footer className={`footer-arcobo-footer ${props.rootClassName} `}>
      <div className="footer-arcobo-container">
        <Link to="/" className="footer-arcobo-navlink">
          <img
            alt={props.imageAlt1}
            src={props.imageSrc1}
            className="footer-arcobo-image"
          />
        </Link>
        <span className="footer-arcobo-text">
          <span className="">© 2024 Arcobo LLP,</span>
          <br className=""></br>
          <span className=""> All Rights Reserved.</span>
          <br className=""></br>
          <span className="">info@arcobo.tech</span>
          <br className=""></br>
        </span>
        <div className="footer-arcobo-icon-group">
          <a
            href="https://t.me/arcobo"
            target="_blank"
            rel="noreferrer noopener"
            className="footer-arcobo-link"
          >
            <svg viewBox="0 0 1024 1024" className="footer-arcobo-icon">
              <path
                d="M679.429 746.857l84-396c7.429-34.857-12.571-48.571-35.429-40l-493.714 190.286c-33.714 13.143-33.143 32-5.714 40.571l126.286 39.429 293.143-184.571c13.714-9.143 26.286-4 16 5.143l-237.143 214.286-9.143 130.286c13.143 0 18.857-5.714 25.714-12.571l61.714-59.429 128 94.286c23.429 13.143 40 6.286 46.286-21.714zM1024 512c0 282.857-229.143 512-512 512s-512-229.143-512-512 229.143-512 512-512 512 229.143 512 512z"
                className=""
              ></path>
            </svg>
          </a>
          <a
            href="https://www.linkedin.com/company/arcobotech"
            target="_blank"
            rel="noreferrer noopener"
            className="footer-arcobo-link1"
          >
            <svg
              viewBox="0 0 877.7142857142857 1024"
              className="footer-arcobo-icon2"
            >
              <path
                d="M135.429 808h132v-396.571h-132v396.571zM276 289.143c-0.571-38.857-28.571-68.571-73.714-68.571s-74.857 29.714-74.857 68.571c0 37.714 28.571 68.571 73.143 68.571h0.571c46.286 0 74.857-30.857 74.857-68.571zM610.286 808h132v-227.429c0-121.714-65.143-178.286-152-178.286-70.857 0-102.286 39.429-119.429 66.857h1.143v-57.714h-132s1.714 37.143 0 396.571v0h132v-221.714c0-11.429 0.571-23.429 4-32 9.714-23.429 31.429-48 68-48 47.429 0 66.286 36 66.286 89.714v212zM877.714 237.714v548.571c0 90.857-73.714 164.571-164.571 164.571h-548.571c-90.857 0-164.571-73.714-164.571-164.571v-548.571c0-90.857 73.714-164.571 164.571-164.571h548.571c90.857 0 164.571 73.714 164.571 164.571z"
                className=""
              ></path>
            </svg>
          </a>
        </div>
      </div>
      <div className="footer-arcobo-links-container">
        <div className="footer-arcobo-container1">
          <div className="footer-arcobo-container2">
            <span className="footer-arcobo-text07">{props.text}</span>
            <span className="footer-arcobo-text08">{props.text1}</span>
            <span className="footer-arcobo-text09">{props.text2}</span>
          </div>
          <div className="footer-arcobo-container3">
            <span className="footer-arcobo-text10">{props.text5}</span>
            <span className="footer-arcobo-text11">{props.text6}</span>
            <span className="footer-arcobo-text12">{props.text7}</span>
            <span className="footer-arcobo-text13">{props.text8}</span>
          </div>
        </div>
        <div className="footer-arcobo-container4">
          <div className="footer-arcobo-container5">
            <span className="footer-arcobo-text14">{props.text10}</span>
            <span className="footer-arcobo-text15">{props.text11}</span>
            <Link to="/privacypolicy" className="footer-arcobo-navlink1">
              {props.text12}
            </Link>
            <span className="footer-arcobo-text16">{props.text13}</span>
            <span className="">{props.text14}</span>
          </div>
        </div>
      </div>
    </footer>
  )
}

FooterArcobo.defaultProps = {
  text5: 'Company',
  text8: 'Contact',
  text19: 'Product',
  text15: 'Resources',
  imageSrc1: '/logo/main_logo_arcobo.svg',
  text1: 'Features',
  text6: 'About',
  text10: 'Support',
  text2: 'Pricing',
  text9: 'Blog',
  imageSrc: 'https://presentation-website-assets.teleporthq.io/logos/logo.png',
  imageAlt: 'logo',
  text14: 'Help center',
  text7: 'Careers',
  text: 'Product',
  text12: 'Privacy Policy',
  text16: 'Blog',
  text3: 'Tutorials',
  text13: 'Legal',
  text4: 'Releases',
  text17: 'Pricing',
  imageAlt1: 'image',
  text11: 'Terms of service',
  text18: 'Service',
  rootClassName: '',
}

FooterArcobo.propTypes = {
  text5: PropTypes.string,
  text8: PropTypes.string,
  text19: PropTypes.string,
  text15: PropTypes.string,
  imageSrc1: PropTypes.string,
  text1: PropTypes.string,
  text6: PropTypes.string,
  text10: PropTypes.string,
  text2: PropTypes.string,
  text9: PropTypes.string,
  imageSrc: PropTypes.string,
  imageAlt: PropTypes.string,
  text14: PropTypes.string,
  text7: PropTypes.string,
  text: PropTypes.string,
  text12: PropTypes.string,
  text16: PropTypes.string,
  text3: PropTypes.string,
  text13: PropTypes.string,
  text4: PropTypes.string,
  text17: PropTypes.string,
  imageAlt1: PropTypes.string,
  text11: PropTypes.string,
  text18: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default FooterArcobo
