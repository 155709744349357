import React from 'react'

import { Helmet } from 'react-helmet'

import HeaderArcobo from '../components/header-arcobo'
import FooterArcobo from '../components/footer-arcobo'
import './technology.css'

const Technology = (props) => {
  return (
    <div className="technology-container">
      <Helmet>
        <title>Technology - Arcobo</title>
        <meta
          name="description"
          content="Производство и металлообработка для крупногабаритных металлических изделий. Получите уникальные детали, изготовленные с профессиональным мастерством."
        />
        <meta property="og:title" content="Technology - Arcobo" />
        <meta
          property="og:description"
          content="Производство и металлообработка для крупногабаритных металлических изделий. Получите уникальные детали, изготовленные с профессиональным мастерством."
        />
      </Helmet>
      <HeaderArcobo rootClassName="header-arcobo-root-class-name1"></HeaderArcobo>
      <div className="technology-hero">
        <div className="technology-container01">
          <h1 className="technology-text">Traditional way</h1>
          <h1 className="technology-text01">
            <span className="technology-text02">
              Traditional subtractive manufacturing involves removing material
              from a solid block to create the desired metal product. This
              process typically utilizes machining techniques such as milling,
              turning, or drilling to achieve the final shape and dimensions of
              the metal workpiece.
            </span>
            <br></br>
            <br></br>
            <span>The main drawbacks of this approach are:</span>
            <br></br>
            <span>- the need for large-sized blanks</span>
            <br></br>
            <span>- material waste in the form of metal chips</span>
            <br></br>
            <span>- wear of expensive cutting tools</span>
            <br></br>
          </h1>
        </div>
        <div className="technology-container02">
          <img
            alt="image"
            src="/icons/traditional_way.svg"
            className="technology-image"
          />
          <span className="technology-text13">
            Machining from a large-sized blank
          </span>
        </div>
      </div>
      <div className="technology-hero1">
        <div className="technology-container03">
          <img
            alt="image"
            src="/icons/waam_way.svg"
            className="technology-image1"
          />
          <span className="technology-text14">
            Printing from standard wire of the required part.
          </span>
        </div>
        <div className="technology-container04">
          <h1 className="technology-text15">New way - Additional Way!</h1>
          <h1 className="technology-text16">
            <span>
              Wire Arc Additive Manufacturing (WAAM) revolutionizes metal
              production by layering metal wire to build up a component, rather
              than subtracting material from a solid block. This innovative
              process starts with a 3D model of the desired item, which guides a
              robotic arm to precisely deposit layers of metal wire, melting it
              with an electric arc. WAAM offers unparalleled flexibility,
              allowing the creation of complex geometries with minimal material
              waste. Its efficiency, speed, and ability to work with various
              metal alloys make it ideal for rapid prototyping and small batch
              production. Moreover, WAAM&apos;s cost-effectiveness and minimal
              need for large-scale tooling and setup changes set it apart from
              traditional manufacturing methods, positioning it as a leading
              contender in the realm of metal fabrication.
            </span>
            <br></br>
          </h1>
        </div>
      </div>
      <div className="technology-banner">
        <h1 className="technology-text19">
          When to use additive technologies?
        </h1>
        <span className="technology-text20">
          WAAM offers a significant advantage in lead times by reducing
          production time from months to weeks or days, which cannot be achieved
          by traditional technologies. The economic efficiency of parts produced
          by milling is often assessed by the Buy-to-Fly (BTF) ratio, which is
          the ratio of raw material weight to the final part weight. Traditional
          methods can result in BTF ratios reaching double digits, while WAAM
          tends to have a BTF ratio of one. WAAM can result in cost savings of
          up to 70% compared to machining from solid billets or open-die
          forging.
        </span>
        <img
          alt="image"
          src="/icons/waam_graph.svg"
          className="technology-image2"
        />
        <span className="technology-text21">
          The dependency of Feasibility on the BTF coefficient
        </span>
      </div>
      <div className="technology-banner1">
        <h1 className="technology-text22">Our Mission</h1>
        <span className="technology-text23">
          <span>
            For the fastest production of large metal parts, Arcobo utilizes the
            WAAM 3D printing technology. WAAM technology is based on
            layer-by-layer 3D printing of metal wire according to the designed
            3D model of the product. The geometry of the product corresponds to
            the developed model due to the high precision of robot movement and
            constant process control using scanners and additional sensors.
          </span>
          <br></br>
          <br></br>
          <span>
            This technology is suitable for all weldable alloys and is
            indispensable for prototyping and manufacturing small batches of
            parts, where specific time costs lead to a significant increase in
            costs. The competitive advantage over traditional methods (milling,
            casting, welding) is the absence of the need for large-scale tooling
            and reconfiguration of the production line.
          </span>
          <br></br>
          <br></br>
          <span>
            The low cost of manufacturing large metal parts is achieved through
            the use of common welding wire, the use of a highly efficient heat
            source - the electric arc, and more economical equipment, based on
            an industrial robot and the latest generation welding source.
          </span>
          <br></br>
          <br></br>
          <span>
            During the printing of each layer, the metal wire is melted by the
            electric arc, creating a monolithic product without any
            discontinuities or gaps. At the same time, modern welding sources
            allow for precise control of material transfer and the formation of
            the structure and mechanical properties of the metal, which are
            optimal for the task at hand.
          </span>
          <br></br>
          <br></br>
          <span>
            The method&apos;s productivity surpasses any other 3D printing
            methods and for some alloys reaches 5 kg/hour, while the maximum
            dimensions of the product are unlimited. Installing the robot on
            rails or vertical lifts allows for the creation of unified
            structures measuring over ten meters!
          </span>
        </span>
      </div>
      <div className="technology-gallery">
        <div className="technology-gallery1">
          <div className="technology-container05">
            <span className="technology-text37">waammat.com</span>
          </div>
          <div className="technology-container06">
            <span className="technology-text38">mx3d.com</span>
          </div>
          <div className="technology-container07">
            <span className="technology-text39">lincolnelectric.com</span>
          </div>
          <div className="technology-container08">
            <span className="technology-text40">hydroreview.com</span>
          </div>
          <div className="technology-container09">
            <span className="technology-text41">weibo.com</span>
          </div>
          <div className="technology-container10">
            <span className="technology-text42">andritz.com</span>
          </div>
        </div>
      </div>
      <FooterArcobo rootClassName="footer-arcobo-root-class-name2"></FooterArcobo>
    </div>
  )
}

export default Technology
