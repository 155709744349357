import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import NavigationLinks from './navigation-links'
import './header-arcobo.css'

const HeaderArcobo = (props) => {
  return (
    <div className={`header-arcobo-container ${props.rootClassName} `}>
      <header data-role="Header" className="header-arcobo-header">
        <Link to="/" className="header-arcobo-navlink">
          <img
            alt={props.imageAlt}
            src={props.imageSrc}
            className="header-arcobo-image"
          />
        </Link>
        <div className="header-arcobo-container1">
          <div className="header-arcobo-nav">
            <NavigationLinks
              rootClassName="rootClassName17"
              className=""
            ></NavigationLinks>
          </div>
        </div>
      </header>
    </div>
  )
}

HeaderArcobo.defaultProps = {
  text: 'Language:',
  rootClassName: '',
  language: 'EN',
  imageSrc: '/logo/main_logo_arcobo.svg',
  imageAlt: 'image',
}

HeaderArcobo.propTypes = {
  text: PropTypes.string,
  rootClassName: PropTypes.string,
  language: PropTypes.string,
  imageSrc: PropTypes.string,
  imageAlt: PropTypes.string,
}

export default HeaderArcobo
