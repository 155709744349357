import React from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './navigation-links.css'

const NavigationLinks = (props) => {
  return (
    <nav className={`navigation-links-nav ${props.rootClassName} `}>
      <a
        href="https://calc.arcobo.tech"
        target="_blank"
        rel="noreferrer noopener"
        className="navigation-links-text"
      >
        {props.text}
      </a>
      <Link to="/technology" className="navigation-links-navlink">
        {props.text1}
      </Link>
      <span className="navigation-links-text1">{props.text2}</span>
      <span className="navigation-links-text2">{props.text3}</span>
      <span className="navigation-links-text3">{props.text4}</span>
      <div className="navigation-links-container">
        <span className="navigation-links-text4">{props.text5}</span>
        <select className="navigation-links-select">
          <option value="Option 1" className="">
            EN
          </option>
          <option value="Option 2" className="">
            RU
          </option>
          <option value="Option 3" className="">
            KZ
          </option>
        </select>
      </div>
    </nav>
  )
}

NavigationLinks.defaultProps = {
  text: 'Online calculator',
  text2: 'Solutions',
  text5: 'Language:',
  text3: 'Application',
  text4: 'About us',
  text1: 'Technology',
  rootClassName: '',
}

NavigationLinks.propTypes = {
  text: PropTypes.string,
  text2: PropTypes.string,
  text5: PropTypes.string,
  text3: PropTypes.string,
  text4: PropTypes.string,
  text1: PropTypes.string,
  rootClassName: PropTypes.string,
}

export default NavigationLinks
